import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/account": [13],
		"/(protected)/account/[email]": [5,[2]],
		"/(protected)/admin": [6,[2]],
		"/(protected)/admin/logs": [7,[2]],
		"/(protected)/admin/members": [8,[2]],
		"/(protected)/admin/messages": [9,[2]],
		"/(protected)/admin/results": [10,[2]],
		"/(protected)/admin/submissions": [11,[2]],
		"/changelog": [14],
		"/email-link": [15],
		"/events": [16],
		"/(protected)/events/[email]": [12,[2,3]],
		"/intake": [17],
		"/results": [18],
		"/stats": [19]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';